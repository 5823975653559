import React, { Component, Fragment } from "react";
import img1 from "../../../../assets/img/Stempel.jpg";
import { NavLink } from "react-router-dom";

import {
  Form,
  FormGroup,
  Notification,
  FormControl,
  SelectPicker,
  Button,
  ControlLabel,
  Schema,
  Loader,
  Progress,
  Checkbox,
  CheckboxGroup,
} from "rsuite";
import ModalForm from "../../../../components/modal";

//api
import { ProfessionalDatumstempel } from "../../../../api/Api";

import { emit } from "jetemit";
const { StringType, NumberType, BooleanType } = Schema.Types;
const model = Schema.Model({
  // email: StringType()
  //   .isEmail("Please enter a valid email address.")
  //   .isRequired("This field is required."),
  count: NumberType("Please enter a valid number.")
    .min(1, "Minimum 1")
    .isRequired("This field is required."),
  width: NumberType().isRequired("Endformat is required."),
  materialId: StringType().isRequired("material is required."),
  doubleSided: BooleanType().isRequired("doubleSided field is required."),
  isCellophane: BooleanType().isRequired("isCellophane field is required."),
  colorful: BooleanType().isRequired("colorful field is required."),
});

const model1 = Schema.Model({
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),
  phoneNumber: StringType().isRequired("This field is required."),
});

export default class gride extends Component {
  state = {
    materialShow: false,
    progress: false,
    percent: 0,
    src1: "",
    src2: "",
    cellophaneType: [
      {
        label: "Matt",
        value: "Matt",
      },
      { label: "glänzend", value: "glänzend" },
    ],
    shipment: [
      { label: "pickup", value: "pickup" },
      // {
      //   label: "send",
      //   value: "send",
      // },
    ],
    steps: "step1",
    doubleSided: [
      {
        label: "beidseitig",
        value: true,
      },
      { label: "einseitig", value: false },
    ],
    colorful: [
      {
        label: "S/W",
        value: false,
      },
      { label: "farbig", value: true },
    ],
    isCellophane: [
      {
        label: "Ja",
        value: true,
      },
      { label: "Nein", value: false },
    ],
    checkDatenverwendung: [],
    material: [],
    initialValues: {
      width: "",
      height: "",
      count: 1,
      isCellophane: false,
      colorful: false,
      doubleSided: false,
      description: "",
      customertype: "",
      firstName: "",
      lastName: "",
      postalCode: "",
      country: "",
      email: "",
      gender: "",
      address: "",
      street: "",
      phoneNumber: "",
      cellophaneType: "Matt",
      shipment: "pickup",
    },
    formError: {},
    loading: false,
    loading1: false,
    loadingRecord: false,
  };
  open = (funcName, title, message) => {
    Notification[funcName]({
      title: title,
      description: <div style={{ color: "black" }}>{message}</div>,
    });
  };

  componentDidMount() {
    this.getEndFormat();
  }

  getEndFormat = async () => {
    try {
      const { data } = await ProfessionalDatumstempel.getAllEndFormat();
      let newData = [];
      data.data.forEach((element) => {
        newData = [
          ...newData,
          {
            label: element.key,
            value: element.width.toString() + "*" + element.height.toString(),
          },
        ];
      });

      this.setState({ endFormat: newData });
    } catch (error) {
      console.error(error);
    }
  };

  onChangeEndFormat = async (name, value) => {
    let strWidth = value && value.substring(0, value.indexOf("*"));
    let strHeight = value && value.split("*")[1];

    await this.setState({
      initialValues: {
        ...this.state.initialValues,
        width: strWidth,
        height: strHeight,
      },
    });
    this.getMaterial();
  };

  onChangeMaterialId = async (name, value) => {
    await this.setState({
      initialValues: {
        ...this.state.initialValues,
        [name]: value,
      },
    });
    this.getMaterial();
  };

  onChange = async (name, value) => {
    await this.setState({
      initialValues: {
        ...this.state.initialValues,
        [name]: value,
      },
    });
    this.getMaterial();
    if (name === "isCellophane") {
      this.setState({ materialShow: true });
      this.getMaterial();
    }
  };
  debounceTimeout;
  getMaterial = async () => {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(async () => {
      try {
        const { data } = await ProfessionalDatumstempel.digiPrintMaterial(
          this.state.initialValues
        );
        let newData = [];
        data.data.forEach((element) => {
          newData = [
            ...newData,
            {
              label: element.key + "  € " + element.price + ",- inkl. 20% USt.",
              value: element.id,
            },
          ];
        });

        this.setState({ material: newData, materialShow: true });
      } catch (error) {
        console.error(error);
      }
    }, 500);
  };

  handleStep = async (step) => {
    if (step === 1) {
      this.getMaterial();
      this.setState({ steps: `step${step}` });

      return;
    }
    if (step === 2) {
      if (await !this.form.check()) {
        if (this.state.formError.materialId) {
          this.open("error", "error", this.state.formError.materialId);
        }
        if (this.state.formError.width) {
          this.open("error", "error", this.state.formError.width);
        }
        if (this.state.formError.colorful) {
          this.open("error", "error", this.state.formError.colorful);
        }
        if (this.state.formError.doubleSided) {
          this.open("error", "error", this.state.formError.doubleSided);
        }

        console.error("Form Error");
        return;
      }
      this.setState({ steps: `step${step}` });
    }
  };

  handleChangefileOne = async ({ target }) => {
    this.setState({ loading: true });
    try {
      let file = target.files[0],
        src = target.value;
      var reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            file1: file,
          },
          file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);

      if (target.files && target.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ image1: e.target.result });
        };
        reader.readAsDataURL(target.files[0]);
      }

      this.setState({ file, src1: src });
    } catch (error) {}
    setTimeout(() => {
      this.setState({ loading: false });
    }, 5000);
  };
  handleChangefileTow = async ({ target }) => {
    this.setState({ loading1: true });
    try {
      let file = target.files[0],
        src = target.value;
      var reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          initialValues: {
            ...this.state.initialValues,
            file2: file,
          },
          file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
      if (target.files && target.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ image2: e.target.result });
        };
        reader.readAsDataURL(target.files[0]);
      }

      this.setState({ file, src2: src });
    } catch (error) {}
    setTimeout(() => {
      this.setState({ loading1: false });
    }, 5000);
  };

  loaderPercentage = (percent) => {
    let percentCompleted = Math.floor((percent.loaded / percent.total) * 100);
    this.setState({ percent: percentCompleted });
  };

  personalDataChecker = () => {
    const personalData = JSON.parse(localStorage.getItem("personalData"));
    if (!personalData || !personalData.phoneNumber) {
      this.setState({ openNotifHandleInfo: true });
      this.setState({ show: true });

      this.open("error", "error", "please enter your information");
      return { hasError: true, personalData: "" };
    } else {
      this.setState({ openNotifHandleInfo: false });
      return { hasError: false, personalData: personalData };
    }
  };
  handleSubmit = async () => {
    const { hasError, personalData } = this.personalDataChecker();
    if (hasError) {
      return;
    }

    await this.setState({
      initialValues: {
        ...this.state.initialValues,
        customertype: personalData.customertype,
        firstName: personalData.firstName,
        lastName: personalData.lastName,
        postalCode: personalData.postalCode,
        country: personalData.country,
        email: personalData.email,
        gender: personalData.gender,
        address: personalData.address,
        street: personalData.street,
        phoneNumber: personalData.phoneNumber,
        materialId: this.state.materialId,
      },
    });

    try {
      // if (await !this.form1.check()) {
      //   if (this.state.formError1.email) {
      //     this.open("error", "error", this.state.formError1.email);
      //   }
      //   if (this.state.formError1.phoneNumber) {
      //     this.open("error", "error", this.state.formError1.phoneNumber);
      //   }

      //   console.error("Form Error");
      //   return;
      // }
      if (!this.state.file) {
        return this.open("error", "error", "file 1 is requierd");
      }
      if (!this.state.checkDatenverwendung.length) {
        return this.open("error", "error", "check Datenverwendung is requierd");
      }
      this.setState({ loadingRecord: true, progress: true });
      const { data } = await ProfessionalDatumstempel.record(
        this.state.initialValues,
        this.loaderPercentage
      );

      this.setState({ file1: "", file2: "" });

      // await localStorage.setItem(
      //   "phoneNumber",
      //   this.state.initialValues.phoneNumber
      // );
      // await localStorage.setItem("email", this.state.initialValues.email);
      emit("order", data);
      this.open(
        "success",
        "Bestätigung",
        "Dieser Artikel wurde Ihrem Einkaufswagen hinzugefügt."
      );
      this.handleStep(1);
    } catch (error) {
      this.open("error", "error", "there is a problem");
    }
    this.setState({ loadingRecord: false, progress: false });
  };

  handleOpen = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    const token =
      localStorage.getItem("token") && localStorage.getItem("token");
    const {
      endFormat,
      doubleSided,
      colorful,
      isCellophane,
      steps,
      material,
      materialShow,
      loading,
      loading1,
      image2,
      progress,
      percent,
      show,
      cellophaneType,
      shipment,
      initialValues,
    } = this.state;
    return (
      <Fragment>
        <main>
          <ModalForm
            open={show}
            handleClose={this.handleClose}
            personalDataChecker={this.personalDataChecker}
          />
          {progress ? (
            <div className="loader-progress flex-j-c">
              <div className="percent-loader">
                <div
                  style={{
                    width: 120,
                    display: "inline-block",
                    marginRight: 10,
                  }}
                >
                  <Progress.Circle
                    percent={percent}
                    strokeColor="#f58634"
                    status={percent === 100 && "success"}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="main1-product-detail w-100 flex-j-c">
            <div className="main1-product-detail-dad w-90 flex-j-s">
              <div className="main1-product-detail-child1">
                <i className="mdi mdi-tune"></i>
                <strong>Filter</strong>
              </div>
              <div className="main1-product-detail-child-left w-40">
                <div className="main1-product-detail-child-left-img1">
                  <img src={img1} alt="" />
                </div>
                <div className="flex m-t-20">
                  <div className="main1-product-detail-child-left-img2">
                    <img src={img1} alt="" />
                  </div>
                  {/* <div className="main1-product-detail-child-left-img2-2">
                    <img src={img2} alt="" />
                  </div> */}
                </div>
              </div>
              <div className="main1-product-detail-child-right w-80">
                <div className="main1-product-detail-child1-right-txt1">
                  <a href="https://teleprint.at">Startseite</a>
                  <span className="divider">/</span>
                  <a href="https://teleprint.at/kategorie/Folder/">Stempel</a>
                </div>
                <div className="main1-product-detail-child1-right-txt2">
                  Professional Datumstempel
                </div>
                {/* <div className="main1-product-detail-icn-res flex">
                  <a
                    href="https://teleprint.at/produkte/Folder-einfachfalz/"
                    rel="next"
                    className="button icon is-outline circle"
                  >
                    <i className="mdi mdi-less-than"></i>
                    <i className="mdi mdi-greater-than"></i>
                  </a>
                </div> */}
                {/* <div className="main1-product-detail-child1-right-txt3">
                  Der Preis wird nach Anfrage angegeben und Sie werden
                  benachrichtigt
                </div> */}

                <div className="main1-product-detail-child1-right-box1 flex-j-c">
                  <div className="w-95">
                    {steps === "step1" ? (
                      <Form
                        onCheck={(formError) => {
                          this.setState({ formError });
                        }}
                        ref={(ref) => (this.form = ref)}
                        formValue={this.state.initialValues}
                        model={model}
                        layout="horizontal"
                        fluid
                      >
                        <div className="head1-dad-down-right-inpt-anzahl-form .rs-input">
                          <FormGroup className="marg-right20 head1-dad-down-right-inpt-anzahl flex">
                            <ControlLabel>Anzahl:</ControlLabel>{" "}
                            <FormControl
                              className=""
                              placeholder="Anzahl"
                              min="1"
                              name="count"
                              type="number"
                              required
                              onChange={(e) => this.onChange("count", e)}
                              style={{ width: "100%" }}
                            />
                          </FormGroup>
                        </div>
                        <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                          <ControlLabel>Endformat:</ControlLabel>{" "}
                          <SelectPicker
                            style={{ width: "80%" }}
                            onChange={(e) =>
                              this.onChangeEndFormat("endFormatId", e)
                            }
                            name="endFormatId"
                            className=""
                            data={endFormat}
                            required
                            placeholder="Endformat"
                            accepter={SelectPicker}
                          />
                        </FormGroup>
                        <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                          <ControlLabel>Druck:</ControlLabel>{" "}
                          <SelectPicker
                            searchable={false}
                            style={{ width: "80%" }}
                            onChange={(e) => this.onChange("doubleSided", e)}
                            className=""
                            name="doubleSided"
                            data={doubleSided}
                            required
                            placeholder="Druck"
                            accepter={SelectPicker}
                          />
                        </FormGroup>
                        <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                          <ControlLabel>Farbigkeit:</ControlLabel>{" "}
                          <SelectPicker
                            searchable={false}
                            style={{ width: "80%" }}
                            onChange={(e) => this.onChange("colorful", e)}
                            className=""
                            name="colorful"
                            data={colorful}
                            required
                            placeholder="Farbigkeit"
                            accepter={SelectPicker}
                          />
                        </FormGroup>
                        <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                          <ControlLabel>Zellophan:</ControlLabel>{" "}
                          <SelectPicker
                            style={{ width: "80%" }}
                            searchable={false}
                            onChange={(e) => this.onChange("isCellophane", e)}
                            name="isCellophane"
                            className=""
                            data={isCellophane}
                            required
                            placeholder="Zellophan"
                            accepter={SelectPicker}
                          />
                        </FormGroup>
                        {initialValues.isCellophane ? (
                          <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                            <ControlLabel>Zellophan:</ControlLabel>
                            <SelectPicker
                              style={{ width: "80%" }}
                              searchable={false}
                              onChange={(e) =>
                                this.onChange("cellophaneType", e)
                              }
                              defaultValue={cellophaneType[0].value}
                              name="cellophaneType"
                              className=""
                              data={cellophaneType}
                              required
                              placeholder="cellophaneType"
                              accepter={SelectPicker}
                            />
                          </FormGroup>
                        ) : (
                          ""
                        )}
                        {materialShow ? (
                          <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                            <ControlLabel>Material:</ControlLabel>{" "}
                            <SelectPicker
                              style={{ width: "80%", marginBottom: "20px" }}
                              onChange={(e) => this.onChange("materialId", e)}
                              name="materialId"
                              className="rightInput"
                              data={material}
                              required
                              placeholder="Material"
                              accepter={SelectPicker}
                            />
                          </FormGroup>
                        ) : (
                          ""
                        )}
                      </Form>
                    ) : (
                      <>
                        {!token ? (
                          <div className="flex-j-c ">
                            <div>
                              <Button
                                style={{
                                  width: "200px",
                                  padding: "10px",
                                  margin: "15px",
                                }}
                                onClick={this.handleOpen}
                              >
                                information
                              </Button>
                              {this.state.openNotifHandleInfo ? (
                                <div style={{ color: "red" }}>
                                  enter your information from hear
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <Form
                          onCheck={(formError1) => {
                            this.setState({ formError1 });
                          }}
                          ref={(ref) => (this.form1 = ref)}
                          formValue={this.state.initialValues}
                          model={model1}
                          layout="horizontal"
                          fluid
                        >
                          {/* <FormGroup className="marg-right20 head1-dad-down-right-inpt-1">
                          <ControlLabel>Telefonnummer:</ControlLabel>{" "}
                          <FormControl
                            className="rightInput"
                            placeholder="Telefonnummer"
                            name="phoneNumber"
                            type="number"
                            required
                            onChange={(e) => this.onChange("phoneNumber", e)}
                          />
                        </FormGroup>

                        <FormGroup className="marg-right20 head1-dad-down-right-inpt-1">
                          <ControlLabel>Email:</ControlLabel>{" "}
                          <FormControl
                            className="rightInput"
                            placeholder="Email"
                            name="email"
                            type="text"
                            required
                            onChange={(e) => this.onChange("email", e)}
                          />
                        </FormGroup> */}

                          <div className="main1-product-detail-child1-right-box1 flex-j-c">
                            <div className="w-95">
                              <div className="main1-product-detail-child1-right-box1-txt1">
                                Ihre Datei(en) hochladen
                              </div>
                              <div className="main1-product-detail-child1-right-box1-txt2">
                                Bitte laden Sie ausschließlich geschlossene
                                Dateiformate hoch: PDF, JPG, JPEG, PNG, TIFF
                              </div>

                              <div className="main1-product-detail-child1-right-box1-1">
                                <div
                                  style={{
                                    position: "relative",
                                    zIndex: "999",
                                  }}
                                  className="main1-product-detail-child1-right-box1-1-icn t-c"
                                >
                                  {loading ? (
                                    <Loader center content="loading" />
                                  ) : (
                                    ""
                                  )}

                                  {this.state.image1 ? (
                                    <i
                                      style={{
                                        position: "absolute",
                                        top: "-11px",
                                        zIndex: "-5",
                                      }}
                                      className="mdi mdi-file-pdf"
                                    ></i>
                                  ) : (
                                    <i
                                      style={{
                                        position: "absolute",
                                        top: "-11px",
                                        zIndex: "-5",
                                      }}
                                      className="mdi mdi-arrow-collapse-down"
                                    ></i>
                                  )}

                                  <div>
                                    <label className="custom-file-upload main1-lab-bargozari-ax">
                                      <div>
                                        <input
                                          name="image"
                                          type="file"
                                          multiple
                                          id="file"
                                          // accept="image/*"
                                          onChange={(e) =>
                                            this.handleChangefileOne(e)
                                          }
                                        />
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="main1-product-detail-child1-right-box1-1">
                                <div
                                  style={{
                                    position: "relative",
                                    zIndex: "999",
                                  }}
                                  className="main1-product-detail-child1-right-box1-1-icn t-c"
                                >
                                  {loading1 ? (
                                    <Loader center content="loading" />
                                  ) : (
                                    ""
                                  )}
                                  {image2 ? (
                                    // <img
                                    //   style={{
                                    //     width: "47px",
                                    //     height: "47px",
                                    //     position: "absolute",
                                    //   }}
                                    //   alt=""
                                    //   src={image2}
                                    // />
                                    <i
                                      style={{
                                        position: "absolute",
                                        top: "-11px",
                                        zIndex: "-5",
                                      }}
                                      className="mdi mdi-file-pdf"
                                    ></i>
                                  ) : (
                                    <i
                                      style={{
                                        position: "absolute",
                                        top: "-11px",
                                        zIndex: "-5",
                                      }}
                                      className="mdi mdi-arrow-collapse-down"
                                    ></i>
                                  )}
                                  <div>
                                    <label className="custom-file-upload main1-lab-bargozari-ax">
                                      <div>
                                        <input
                                          name="image"
                                          type="file"
                                          multiple
                                          id="file"
                                          // accept="image/*"
                                          onChange={(e) =>
                                            this.handleChangefileTow(e)
                                          }
                                        />
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                            <ControlLabel>shipment:</ControlLabel>
                            <SelectPicker
                              defaultValue={shipment[0].value}
                              style={{ width: "80%" }}
                              searchable={false}
                              onChange={(e) => this.onChange("shipment", e)}
                              name="shipment"
                              className=""
                              data={shipment}
                              required
                              placeholder="shipment"
                              accepter={SelectPicker}
                            />
                          </FormGroup>
                          <div className="main2-buy-child2">
                            <div className="main2-anfrage-txt1">
                              Beschreibung
                            </div>
                            <FormControl
                              onChange={(e) => this.onChange("description", e)}
                              className="main2-buy-txtarea"
                              name="description"
                              rows={10}
                              cols={100}
                              componentClass="textarea"
                            />
                          </div>
                          <CheckboxGroup
                            inline
                            name="checkboxList"
                            value={this.state.value}
                            onChange={(checkDatenverwendung) => {
                              this.setState({ checkDatenverwendung });
                            }}
                          >
                            <Checkbox value={true}>
                              Details zur Datenverwendung und unserer
                              Dienstleister können Sie unserer{" "}
                              <NavLink to="/datenschutzerklärung">
                                Datenschutzerklärung
                              </NavLink>{" "}
                              entnehmen.
                            </Checkbox>{" "}
                          </CheckboxGroup>
                        </Form>
                      </>
                    )}
                  </div>{" "}
                </div>

                <div className="main1-product-detail-btn1 flex-j-s">
                  <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                    <Button
                      appearance="primary"
                      onClick={() => this.handleStep(1)}
                      disabled={steps === "step1"}
                    >
                      Vorherige Stufe
                    </Button>
                  </FormGroup>
                  <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                    {steps === "step1" ? (
                      <Button
                        onClick={() => this.handleStep(2)}
                        appearance="primary"
                      >
                        nächste Stufe
                      </Button>
                    ) : (
                      <Button onClick={this.handleSubmit} appearance="primary">
                        Senden
                      </Button>
                    )}
                  </FormGroup>
                </div>
                {/* <div className="main1-product-detail-btn1">
                  <NavLink to="/buyFolder">
                    <button>kaufen</button>
                  </NavLink>
                </div> */}
              </div>
            </div>
          </div>
          <div className="main2-product-detail flex-j-c w-100">
            <div className="main2-product-detail-dad w-90">
              <div className="main2-product-detail-child1">
                <span>Beschreibung </span>
              </div>
              <div className="main2-product-detail-child2">
                Jedes Unternehmen braucht einen Stempel. Es ist fester
                Bestandteil einer Firma, um schnell und einfach mit der
                persönlichen Unterschrift ein Dokument zu unterzeichnen, das
                Firmenkennzeichen auf das Papier zu bringen oder die Akten
                effizient zu nummerieren. Beim folgenden Stempel handelt es sich
                um den Professional Stempel. Die hochwertige und beste Variante
                des Stempels. Produziert von Marktführer Trodat mit einem festen
                Händegriff, aus hochwertigem Material und ausgezeichnetem Druck
                führt der Professional zu einer professionellen Ausstrahlung.
                Mit 9 unterschiedlichen Formaten als auch 3 unterschiedlichen
                Farben, kann auch zwischen Komplett und Platte ausgewählt
                werden. Das bedeutet, Sie können entweder nur Ihr Design auf
                eine Platte gedruckt bekommen oder zusätzlich direkt samt
                Stempel bestellen.
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}
